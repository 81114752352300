<template>
  <div
    class="modalBg fixed top-0 left-0 w-full bg-overlay z-mobileMenu md:flex justify-center items-center"
    :class="{
      'pointer-events-auto cursor-pointer': closeable,
    }"
    @mousedown.self="close"
  >
    <div
      class="modal bg-white w-full absolute bottom-0 pointer-events-auto !max-h-[90%] md:w-[511px] md:h-auto md:relative md:inline-block overflow-visible cursor-auto"
      :class="[wrapperClass]"
    >
      <div class="content-wrapper overflow-auto w-full h-full pointer-events-auto">
        <div
          v-if="headline || closeable"
          class="top-content relative text-xsmall-medium sm:text-small-medium text-center border-grey200 flex items-center justify-center"
          :class="{
            'border-b h-56' : headline,
          }"
        >
          <div v-if="headline" class="px-56">{{ headline }}</div>
          <button
            v-if="backbutton"
            class="absolute left-0 h-56 w-56 text-center"
            @click="back"
          >
            <img
              :src="'/heroicons/outline/chevron-left.svg?url'"
              alt="back"
              class="inline-block h-20 w-20"
              height="20"
              width="20"
            >
          </button>
          <button
            v-if="closeable"
            class="absolute right-24 top-16 text-center"
            @click="close"
          >
            <img
              :src="'/heroicons/outline/x-mark.svg?url'"
              alt="close"
              class="inline-block h-20 w-20"
              height="20"
              width="20"
            >
          </button>
        </div>
        <div
          class="p-32"
          :class="[ contentClass, {
            'pt-40 sm:pt-40': !headline,
            'pt-16': headline
          }]"
        >
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
interface Props {
  headline?: string,
  closeable?: boolean,
  backbutton?: boolean,
  contentClass?: string,
  wrapperClass?: string,
}
const props = withDefaults(defineProps<Props>(), {
  headline: '',
  closeable: false,
  backbutton: false,
  contentClass: 'px-24 sm:px-32',
  wrapperClass: '',
});
const emit = defineEmits<{
  (event: 'close'): void,
  (event: 'back'): void,
}>();
const close = () => {
  if (props.closeable) {
    emit('close');
  }
};
const back = () => {
  emit('back');
};

onMounted(() => {
  const metaColor = document.querySelector('meta[name="theme-color"]');
  if (metaColor) {
    metaColor.setAttribute('content', '#00374a');
  }
});

onUnmounted(() => {
  const metaColor = document.querySelector('meta[name="theme-color"]');
  if (metaColor) {
    metaColor.setAttribute('content', '#00455D');
  }
});
</script>
<style scoped lang="postcss">
.modalBg {
  height: calc(var(--vh, 1vh) * 100);
}
.modal {
  overflow: auto;
  border-radius: 16px 16px 0 0;
  @screen md {
    border-radius: 16px;
    max-height: calc(var(--vh, 1vh) * 100);
  }
}
.tracking-widget-wrapper {
  min-height: 650px;
  @apply mx-auto block;
  .top-content {
    @apply h-0;
    button {
      @apply absolute top-[35px] right-24;
    }
  }
}
.tracking-widget {
  @apply p-0;
}
.search-widget-wrapper {
  min-height: 650px;
  @apply mx-auto block md:w-[1000px] md:max-w-10/12;
  .top-content {
    @apply sticky top-0 z-50 bg-white;
  }
}
.wrong-country-modal {
  .modal {
    @apply overflow-visible;
  }
}

.modal-book-course {
  .top-content {
    @apply sticky top-0 z-50 bg-white rounded-t-lg;
  }

  .modal {
    @apply h-full sm:h-auto;
  }
}

.wrong-country-modal .content-wrapper {
  @apply overflow-visible;
}
</style>